<template>
	<div class="mainBox">
		<div class="gameIframe" v-if="gameUrl">
			<iframe id="gameIframe" :src="gameUrl" frameborder="0" style="width:100%; height: 100%;"></iframe>
		</div>
		<div class="emptyBox" v-else >{{ tips }}</div>
		
		<!-- 其他App弹窗 -->
		<van-popup
		  v-model:show="showOtherApp"
		  round
		  closeable
		  :close-on-click-overlay="false"
		  @click-close-icon="handleBack"
		>
		<div class="loginBox" style="padding-bottom: 1rem;" v-if="isIos && !isSafari()">
			<img class="tips" src="~@/assets/images/common/ios_tips.png">
			<div class="copyBtn" @click="handleCopyLink">
				<img class="tips" src="~@/assets/images/common/ios_tips_btn.png">
			</div>
		</div>
		<div class="loginBox" v-else>
			<div class="titleTxt">알림</div>
			<p>다음 브라우저로 오픈하세요</p>
			<div class="loginBtn" style="flex-wrap: wrap;margin-top: 12px">
				<!-- <a href="googlechrome://" target="_blank"> -->
				<a href="javascript:;">
					<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-browser-chrome" viewBox="0 0 16 16">
					  <path fill-rule="evenodd" d="M16 8a8.001 8.001 0 0 1-7.022 7.94l1.902-7.098a2.995 2.995 0 0 0 .05-1.492A2.977 2.977 0 0 0 10.237 6h5.511A8 8 0 0 1 16 8ZM0 8a8 8 0 0 0 7.927 8l1.426-5.321a2.978 2.978 0 0 1-.723.255 2.979 2.979 0 0 1-1.743-.147 2.986 2.986 0 0 1-1.043-.7L.633 4.876A7.975 7.975 0 0 0 0 8Zm5.004-.167L1.108 3.936A8.003 8.003 0 0 1 15.418 5H8.066a2.979 2.979 0 0 0-1.252.243 2.987 2.987 0 0 0-1.81 2.59ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"/>
					</svg>
					<span>Google 브라우저</span>
				</a>
				<!-- <a href="samsungapps://" target="_blank" style="margin-top: 12px;"> -->
				<a href="javascript:;" style="margin-top: 12px;">
					<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-browser-edge" viewBox="0 0 16 16">
					  <path d="M9.482 9.341c-.069.062-.17.153-.17.309 0 .162.107.325.3.456.877.613 2.521.54 2.592.538h.002c.667 0 1.32-.18 1.894-.519A3.838 3.838 0 0 0 16 6.819c.018-1.316-.44-2.218-.666-2.664l-.04-.08C13.963 1.487 11.106 0 8 0A8 8 0 0 0 .473 5.29C1.488 4.048 3.183 3.262 5 3.262c2.83 0 5.01 1.885 5.01 4.797h-.004v.002c0 .338-.168.832-.487 1.244l.006-.006a.594.594 0 0 1-.043.041Z"/>
					  <path d="M.01 7.753a8.137 8.137 0 0 0 .753 3.641 8 8 0 0 0 6.495 4.564 5.21 5.21 0 0 1-.785-.377h-.01l-.12-.075a5.45 5.45 0 0 1-1.56-1.463A5.543 5.543 0 0 1 6.81 5.8l.01-.004.025-.012c.208-.098.62-.292 1.167-.285.129.001.257.012.384.033a4.037 4.037 0 0 0-.993-.698l-.01-.005C6.348 4.282 5.199 4.263 5 4.263c-2.44 0-4.824 1.634-4.99 3.49Zm10.263 7.912c.088-.027.177-.054.265-.084-.102.032-.204.06-.307.086l.042-.002Z"/>
					  <path d="M10.228 15.667a5.21 5.21 0 0 0 .303-.086l.082-.025a8.019 8.019 0 0 0 4.162-3.3.25.25 0 0 0-.331-.35c-.215.112-.436.21-.663.294a6.367 6.367 0 0 1-2.243.4c-2.957 0-5.532-2.031-5.532-4.644.002-.135.017-.268.046-.399a4.543 4.543 0 0 0-.46 5.898l.003.005c.315.441.707.821 1.158 1.121h.003l.144.09c.877.55 1.721 1.078 3.328.996Z"/>
					</svg>
					<span>Samsung 브라우저</span>
				</a>
			</div>
		</div>
		</van-popup>
		
		<!-- 登录弹窗 -->
		<Login :showLogin="showLogin" @loginSuccess="loginSuccess" />
		
		<!-- <van-popup
		  v-model:show="showLogin"
		  round
		  :close-on-click-overlay="false"
		>
		<div class="loginBox">
			<div class="titleTxt">타사 로그인</div>
			<div class="loginBtn">
				<div class="btn" @click="handleGoogle" ref="loginBtn">
					<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-google" viewBox="0 0 16 16">
						<path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"/>
					</svg>
					<span>google로그인</span>
				</div>
			</div>
		</div>
		</van-popup> -->
		
		<!-- 实名提示弹窗 -->
		<van-popup
		  v-model:show="showAuthConfim"
		  round
		  :close-on-click-overlay="false"
		>
		<div class="payBox" style="padding: 0 1rem;">
			<div class="titleTxt">알림</div>
			<p>소중한 유저님,</p>
			<p>정책에 따라 게임은 본인 인증이 필요하며, 게임 측에서 귀하의 정보를 누설하지 않습니다.이것은 당신의 게임 신분증으로 사용될 것입니다.</p>
			<p>본인인증 누르셔서 조작하시면 됩니다, 지지해주셔서 감사합니다.</p>
			<div class="payResult" style="margin-top: 1rem;">
				<div class="moneyBox" @click="handleAuthCancel">
					<p class="total">{{ $t('pay.authCancel') }}</p>
				</div>
				<a class="btnBox" :href="authUrl" target="_blank" :class="{'disabled':authLoading == true}">
					{{ $t('pay.authConfirm') }}
				</a>
			</div>
		</div>
		</van-popup>
		
		<!-- 支付页面跳转提示 -->
		<van-popup
		  v-model:show="showPayConfim"
		  round
		  :close-on-click-overlay="false"
		>
		<div class="payBox" style="padding: 0 1rem;">
			<div class="titleTxt">{{ $t('pay.payJumpTitle') }}</div>
			<p>{{ $t('pay.payJumpText') }}</p>
			<div class="payResult" style="margin-top: 1rem;">
				<div class="moneyBox" @click="handlePayCancel">
					<p class="total">{{ $t('common.cancel') }}</p>
				</div>
				<a class="btnBox" :href="payUrl" target="_blank">
					{{ $t('common.confirm') }}
				</a>
			</div>
		</div>
		</van-popup>
		
		<!-- 订单信息弹窗 -->
		<van-popup
		  v-model:show="showPay"
		  round
		  closeable
		  :close-on-click-overlay="false"
		  @click-close-icon="handlePayCancel"
		>
		<div class="payBox">
			<div class="titleTxt">{{ $t('pay.title') }}</div>
			<div class="payForm">
				<van-cell-group :title="orderInfo.good_name">
				  <van-field key="orderMoney"
				      v-model="orderMoney"
				      :label="$t('pay.orderMoney')"
					  input-align="right"
					  readonly
				    >
						<template #button>
						  <span>{{moneyUnit}}</span>
						</template>
				  </van-field>
				  <van-field key="payAllCoupon" v-if="allCoupon > 0"
				      v-model="payAllCoupon"
				      :label="$t('pay.allCoupon')+`：`+allCoupon"
					  type="digit"
					  input-align="right"
					  label-width="56%"
					  readonly
				    >
						<template #button>
							<div class="flexc">
								<van-switch :model-value="checkedAllCoupon" @update:model-value="handleAll" :disabled="payLoading" size="16px" />
							</div>
						</template>
				  </van-field>
				  <van-field key="payHalfCoupon" v-if="halfCoupon > 0"
				      v-model="payHalfCoupon"
				      :label="$t('pay.halfCoupon')+`：`+halfCoupon"
					  type="digit"
					  input-align="right"
					  label-width="56%"
					  readonly
				    >
						<template #button>
							<div class="flexc">
								<van-switch :model-value="checkedHalfCoupon" @update:model-value="handleHalf" :disabled="payLoading" size="16px" />
							</div>
					    </template>
				  </van-field>
				  
				</van-cell-group>
				
			</div>
			<div class="payResult">
				<div class="moneyBox">
					<p class="total">{{ $t('pay.total') }}：{{ orderMoney }}</p>
					<p class="discount" v-if="allCoupon > 0 || halfCoupon > 0">{{ $t('pay.discount') }}：{{ parseInt(payAllCoupon) + parseInt(payHalfCoupon) }}</p>
				</div>
				<div>
					<div class="btnBox" @click.stop="handlePay">
						{{ $t('pay.paybtn') }}
					</div>
					<div class="payTypeTip" @click="showPayType">
						<van-icon name="question-o" />
						결제수단
					</div>
				</div>
			</div>
		</div>
		</van-popup>
		
		<!-- 支付方式弹窗 -->
		<van-popup
		  v-model:show="isShowPayType"
		  round
		  closeable
		  style="top:-55%;width: 90%;height: 90%;position: relative;overflow: auto;"
		  :close-on-click-overlay="false"
		  @click-close-icon="handlePayTypeCancel"
		>
		<div class="payBox payTypeBox">
			<div class="titleTxt">결제수단</div>
			<div style="position: relative;">
				<typeTable></typeTable>
			</div>
		</div>
		</van-popup>
		
		<popupAddScreen ref="popupScreen" :statdes="`玩游戏页领取幸运礼包后`" />
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed, onUnmounted } from 'vue'
	import { useRoute,useRouter } from 'vue-router';
	import useUserStore from '@/store/modules/user'
	import useConfigStore from '@/store/modules/config'
	import { googleTokenLogin } from "vue3-google-login";
	import { googleLogin, getVoucher, getPay, getRealName } from '@/api/game'
	import { gameLogin, gmInfo } from '@/api/gm'
	import { isSafari, getUrlParam } from '@/utils/common'
	import typeTable from '@/view/game/typeTable.vue'
	import useClipboard from 'vue-clipboard3'
	
	import Login from '@/components/login'
	import popupAddScreen from '@/view/coupon/popup.vue'
	
	const { proxy } = getCurrentInstance();
	const { toClipboard } = useClipboard();
	
	// Import the functions you need from the SDKs you need
	import { initializeApp } from "firebase/app";
	import { getAnalytics, logEvent } from "firebase/analytics";
	// Initialize Firebase
	const app = initializeApp(proxy.partten.firebaseConfig);
	const analytics = getAnalytics(app);
	
	const route = useRoute();
	const router = useRouter();
	const showLogin = ref(true)
	const showPay = ref(false)
	const moneyUnit = ref('₩')
	const orderMoney = ref(0)
	const allCoupon = ref(0)//个人全额券
	const halfCoupon = ref(0)//个人半额券
	const payHalfCoupon = ref(0)
	const payAllCoupon = ref(0)
	const checkedAllCoupon = ref(false)
	const checkedHalfCoupon = ref(false)
	const showAuthConfim = ref(false)
	const isShowPayType = ref(false)
	const showPayConfim = ref(false)
	const payLoading = ref(false)
	const payUrl = ref('')
	const authUrl = ref('')
	const orderInfo = ref({})
	const gameId =ref(816)
	// const gameUrl = ref('https://dev-door-qipa-proj6.xianlingwangluo.com/api/door/qipah5login?user_id=9754998&super_game_id=817&platform_id=2005&fcm=1&time=1698741039445&super_token=ZQuc8nx924ra65pKFxSRZ62-_RhM4tnI6ayKwLTB2qgpWF0zFEmniw2HNth3ENp469-Oic15w3Ahrhg5CURoeW64gNpiJBrYQTiBkUK2Z0F9X6inwMCsoWeqLAjrAYsLy7JH40I1SpL590Iv8BYM5m98ryZByGYtbzFY36qAsNw')
	const gameUrl = ref('')
	const osType = ref(useUserStore().ostype)
	const platformId = ref(useUserStore().platformId)
	const googleId = ref(useConfigStore().googleId)
	const UA = ref('')
	const auth2 = ref(null)
	const loginBtn = ref(null)
	const showOtherApp = ref(false)
	const popupScreen = ref(null)
	const timer = ref(null)
	const timerNum = ref(0)
	const authLoading = ref(false)
	const tips = ref('Loading...')
	const isIos = ref(false)
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	const userToken = computed(() => {
		return useUserStore().userToken || ''
	})
	const userInfo = computed(() => {
		return useUserStore().userInfo
	})
	const loginSuccess = () => {
		showLogin.value = false
		proxy.$toast('Loading...')
		if(userInfo.value.platformId == 1 && userInfo.value.gameUrl){
			let gameid = getUrlParam(userInfo.value.gameUrl,'super_game_id')
			if(gameid == gameId.value){
				gameUrl.value = userInfo.value.gameUrl
				useUserStore().setSign(gameUrl.value)
			}
		}else{
			gameLogin(gameId.value).then((res) => {
				if(res.data.realCardNo == 0 && !isMobile.value){
					showAuthConfim.value = true
					handleAuth()
				}else{
					logEvent(analytics, 'login_google_'+gameId.value, { des: '游戏登录' })
					proxy.$toast('Success')
					gameUrl.value = res.data.gameUrl
					useUserStore().setSign(gameUrl.value)
					getCouponNum(res.data.superUserId)
					timerCount()
				}
			}).catch(() => {
				showLogin.value = true
			})
		}
		
	}
	const handleAuth = () => {
		authLoading.value = true
		getRealName({ userId: userInfo.value.userId, superUserId: userInfo.value.superUserId, gameId: gameId.value }).then((res) => {
			authLoading.value = false
			authUrl.value = res
		}).catch(() => {
			authLoading.value = false
			authUrl.value = ''
		})
	}
	onMounted(() => {
		console.log(isSafari())
		UA.value = window.navigator.userAgent
		// if(UA.value.indexOf('; wv') >= 0){
		// 	let newUA = UA.value.replace('; wv','')
		// 	console.log('处理后：'+newUA)
		// 	Object.defineProperty(navigator,'userAgent',{
		// 		value: newUA,
		// 		writable: false
		// 	})
		// }
		isIos.value = !!UA.value.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
		if(isIos.value){
			if(!isSafari()){
				showLogin.value = false
				showOtherApp.value = true
			}
		}else{
			if(UA.value.indexOf('app') >= 0 || UA.value.indexOf('; wv') >= 0){
				// oldVersionInit()
				showLogin.value = false
				showOtherApp.value = true
			}
		}
		
		const query = router.currentRoute.value.params
		console.log(query)
		if(query){
			gameId.value = query.id
			logEvent(analytics, 'view_gameplay_'+gameId.value, { des: 'h5游玩页' })
			if(userToken.value){//盒子已经登录
				loginSuccess()
			}else{
				showLogin.value = true
			}
		}
		// getCouponNum("9754998")
		// window.onload = () => {
		// 子页面接收消息
			let _this = this;
			window.addEventListener('message', function (e) {
				if(e && e.data != 'undefined' && proxy.isJsonString(e.data)){
					let datas = JSON.parse(e.data);
					console.log(datas)
					if(datas.func == 'show_Channel_payBox'){
						orderInfo.value = Object.assign({},JSON.parse(datas.orders))
						orderMoney.value = orderInfo.value.money
						logEvent(analytics, 'buy_order_'+orderInfo.value.game_id, { des: '游戏内购买拉起支付弹窗' })
						openPay()
					}else if(datas.func == 'show_popup'){
						popupScreen.value.addToHome()
						console.log('打开添加主屏幕弹窗')
					}else if(datas.func == 'show_upload_role'){
						if(datas.roles){
							let roleinfo = Object.assign({},JSON.parse(datas.roles))
							if(roleinfo.role_level == 1 || roleinfo.role_level == 30 || roleinfo.role_level == 100){
								logEvent(analytics, 'rolelevel_'+roleinfo.role_level+'_'+gameId.value, { des: '角色上报' })
							}
						}
					}
				}
			}, true)
		// }
		
		document.addEventListener('visibilitychange',function(){
		    if(document.visibilityState==='visible' && useUserStore().userSign){
				showPayConfim.value = false
				payUrl.value = ''
				payHalfCoupon.value = 0
				payAllCoupon.value = 0
				getCouponNum(useUserStore().userInfo.superUserId)
				if(!timer.value){
					timerCount()
				}
		    }else if(document.visibilityState==='hidden' && useUserStore().userSign){
				clearInterval(timer.value)
			}
		});
	})
	onUnmounted(() => {
		clearInterval(timer.value)
		timerNum.value = 0
	})
	const getCouponNum = (suid) => {
		getVoucher({ userId: suid}).then((res) => {
			if(res.data){
				const { voucher, specialVoucher } = res.data
				allCoupon.value = voucher
				halfCoupon.value = specialVoucher
			}
		})
	}
	const timerCount = () => {
		timer.value = setInterval(() => {
			timerNum.value ++
			if(timerNum.value == 10 || timerNum.value == 30 || timerNum.value == 60){
				logEvent(analytics, 'online_'+timerNum.value+'_'+gameId.value, { des: '角色在线时长' })
			}
		},60*1000)
	}
	const openPay = () => {
		showPay.value = true
	}
	const handleAll = (val) => {
		console.log(val)
		checkedAllCoupon.value = val
		if(val){
			checkedHalfCoupon.value = !val
			payHalfCoupon.value = 0
		}else{
			payAllCoupon.value = 0
			return false
		}
		if(allCoupon.value > orderMoney.value){
			payAllCoupon.value = orderMoney.value
		}else{
			payAllCoupon.value = allCoupon.value
		}
	}
	const handleHalf = (val) => {
		checkedHalfCoupon.value = val
		if(val){
			checkedAllCoupon.value = !val
			payAllCoupon.value = 0
		}else{
			payHalfCoupon.value = 0
			return false
		}
		if(halfCoupon.value > orderMoney.value / 2){
			payHalfCoupon.value = orderMoney.value / 2
		}else{
			payHalfCoupon.value = halfCoupon.value
		}
		
	}
	const handlePay = () => {
		console.log('支付')
		console.log(orderInfo.value)
		if(payLoading.value){
			proxy.$toast('Loading...')
			return false
		}
		payLoading.value = true
		let args = {
			orderId: orderInfo.value.game_order_sn,
			totalAmount: orderInfo.value.money,
			userId: orderInfo.value.super_user_id,
			goodId: orderInfo.value.good_id,
			gameId: orderInfo.value.game_id,
			goodName: orderInfo.value.good_name,
			num: 1,
			voucherType: 0,
			voucher: 0,
			source: 1
		}
		// voucherType//0全额，1特殊抵扣50%
		if(checkedAllCoupon.value){
			args.voucherType = 0
			args.voucher = payAllCoupon.value
		}
		if(checkedHalfCoupon.value){
			args.voucherType = 1
			args.voucher = payHalfCoupon.value
		}
		args.totalAmount = Number(orderInfo.value.money) - args.voucher
		getPay(args).then((res) => {
			console.log(res)
			logEvent(analytics, 'h5pay_'+orderInfo.value.game_id, { des: '拉起支付页面或抵扣券支付' })
			if(res.data && res.data.redirectUrl){
				showPay.value = false
				showPayConfim.value = true
				payUrl.value = res.data.redirectUrl
				// window.open(res.data.redirectUrl,'_blank')
			}else{
				showPay.value = false
				proxy.$toast(res.msg)
			}
			payLoading.value = false
		}).catch((err) => {
			payLoading.value = false
		})
	}
	const handlePayCancel = () => {
		if(payLoading.value){
			proxy.$toast('Loading...')
			return false
		}
		payHalfCoupon.value = 0
		payAllCoupon.value = 0
		showPay.value = false
		showPayConfim.value = false
		checkedHalfCoupon.value = false
		checkedAllCoupon.value = false
		payUrl.value = ''
	}
	const handlePayTypeCancel = () =>{
		isShowPayType.value = false
	}
	const showPayType = () =>{
		isShowPayType.value = true
	}
	const handleAuthCancel = () => {
		showAuthConfim.value = false
		authUrl.value = ''
		tips.value = '- fail -'
	}
	const handleGoogle = (e) => {
		if(UA.value.indexOf('nate_app') >= 0){
			showLogin.value = false
			showOtherApp.value = true
		}else{
			googleTokenLogin().then((response) =>{
				console.log(response)
				googleLogin({
					osType: osType.value,
					gameId: gameId.value,
					platformId: platformId.value,
					clientId: googleId.value,
					identityToken: response.access_token
				}).then((res) => {
					useUserStore().setInfo(res.data).then((datas) => {
						logEvent(analytics, 'login_google_'+gameId.value, { des: '谷歌登录' })
						proxy.$toast('Success')
						gameUrl.value = datas.gameUrl
						showLogin.value = false
						useUserStore().setSign(gameUrl.value)
						getCouponNum(datas.superUserId)
						timerCount()
					})
				})
			})
		}
		
	}
	const handleBack = () => {
		history.back()
	}
	const handleCopyLink = async () => {
		let con = window.location.href;//'https://www.gm321321.com/';
		try {
			  await toClipboard(con);
			  proxy.$toast(proxy.$t('common.copySuccess'))
		}catch(e){
			  proxy.$toast(proxy.$t('common.copyFail'))
		}
	}
	// 旧版谷歌登录
	const oldVersionInit = () => {
		// gapi.load('auth2',function(){
		// 	console.log('谷歌js加载成功')
		// })
	}
	const oldVersionLogin = (e) => {
		// gapi.auth2.authorize({
		//   client_id: googleId.value,
		//   scope: 'email profile openid',
		//   response_type: 'id_token permission'
		// }, function(response) {
		//   if (response.error) {
		//     // An error happened!
		//     return;
		//   }
		//   // The user authorized the application for the scopes requested.
		//   var accessToken = response.access_token;
		//   var idToken = response.id_token;
		//   console.log(response)
		//   // You can also now use gapi.client to perform authenticated requests.
		// });
		
	}
</script>

<style lang="less" scoped>
	::-webkit-scrollbar {
	  display: unset !important;
	}
	.mainBox{
		position: relative;
		background: #000;width: 100%;height: calc(100vh - 40px);
		.gameIframe{
			width: 100%;height: 100%;font-size: 0;
		}
		.loginBox,.payBox{
			width: 100%;padding: 0.3125rem 1rem 1.875rem;box-sizing: border-box;
			max-width: 20rem;min-width: 18.75rem;
			img.tips{
				width: 100%;margin: 1.25rem 0 0.75rem;
			}
			.copyBtn{
				width: 80%;margin: 0 auto;
			}
			.titleTxt{
				text-align: center;margin: 0.9375rem 0 1.125rem;font-size: 1.125rem;
			}
			.loginBtn{
				width: 100%;display: flex;justify-content: space-between;
				a,div.btn{
					display: inline-flex;align-items: center;justify-content: center;
					box-sizing: border-box;border-radius: 25px;
					width: 100%;padding: 12px 10px;color: rgba(18,18,18,0.75);
					border: 1px solid rgba(18,18,18,0.75);font-size: 15px;cursor: pointer;
					transition: 0.3s;flex-wrap: wrap;
					span{
						margin-left: 12px;
					}
					&:hover{
						background: rgb(18,18,18);color: #fff;
					}
				}
			}
		}
		.payTypeBox{
			max-width:unset;min-width:unset;
			padding: 0 1rem !important;
		}
		.payBox{
			padding: 0;
			p{margin: 0.625rem 0;}
			.payResult{
				display: flex;align-items: center;justify-content: space-between;
				padding: 1rem;margin-top: 1.875rem;
				.moneyBox{
					.total{
						font-size: 0.9375rem;
					}
					.discount{
						font-size: 0.8125rem;color: #51BAC0;
					}
				}
				.btnBox{
					display: inline-block;
					font-size: 0.9375rem;
					background: linear-gradient(to right,#51BAC0,#85D6DA);color: #fff;
					padding: 0.375rem 1.875rem;border-radius: 0.3125rem;
					&.disabled{
						pointer-events: none;cursor: default;
				
					}
				}
				.payTypeTip{
					font-size: 0.625rem;
					text-align: center;
					color: #969799;
					margin-top: 0.1rem;
				}
			}
		}
	}
</style>